@import "bootstrap-icons/font/bootstrap-icons.css";
@import "~leaflet/dist/leaflet.css";

:root {
    --color-yellow-voxa: #feba00;
    --color-yellow-voxa: #feba00;
    --color-blue-voxa-strong: #071e3b;
    --color-blue-voxa: #10386b;
    --color-background: #f5f5f5;
    --color-white: #fff;
    --color-gray: #ebe7e7;
    --color-gray-bg: #F0F0F0;

    --fs-text-icon: 20px;
    --fs-text-normal: 13px;
    --fs-text-btn: 13px;
    --fs-text-title: 16px;
}

@font-face {
    font-family: "Exo";
    src: url("src/assets/fonts/Exo/static/Exo-Regular.ttf")format("truetype");
}

@font-face {
    font-family: "Exo-Italic";
    src: url("src/assets/fonts/Exo/static/Exo-Italic.ttf")format("truetype");
}

@font-face {
    font-family: "Exo-Light-Italic";
    src: url("src/assets/fonts/Exo/static/Exo-LightItalic.ttf")format("truetype");
}

@font-face {
    font-family: "Exo-Bold";
    src: url("src/assets/fonts/Exo/static/Exo-Bold.ttf")format("truetype");
}

@font-face {
    font-family: "Exo-Bold-Italic";
    src: url("src/assets/fonts/Exo/static/Exo-BoldItalic.ttf")format("truetype");
}

* {
    font-family: "Exo", sans-serif;
}

body {
    background-color: var(--color-gray-bg);
}

.voxa-btn-yellow {
  font-size: var(--fs-text-normal);
  color: var(--color-blue-voxa-strong);
  background-color: var(--color-yellow-voxa);
  border-color: var(--color-yellow-voxa);
}

.voxa-btn-yellow:hover {
  font-size: var(--fs-text-normal);
  color: var(--color-blue-voxa-strong);
  background-color: var(--color-yellow-voxa);
  border-color: var(--color-yellow-voxa);
}

.voxa-btn-blue {
  font-size: var(--fs-text-title);
  color: var(--color-white);
  background-color: var(--color-blue-voxa-strong);
  border-color: var(--color-blue-voxa-strong);
}

.voxa-btn-blue:hover {
  font-size: var(--fs-text-title);
  color: var(--color-white);
  background-color: var(--color-blue-voxa);
  border-color: var(--color-yellow-voxa);
}

/* Custom Table */
.paginator-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.paginator-table thead tr {
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
}

.paginator-table th, td {
  padding: 10px 12px;
  border-bottom: 1px solid #ddd;
}

.paginator-table tbody tr {
  background-color: #fff;
}

.paginator-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.paginator-table tr:hover td{
  background: #839192;
  color: #000;
  transition: all 0.1s ease-in;
}

.paginator-table-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1em;
}

.paginator-table-btn:hover{
  color: #007BFF;
}

.paginator-table-btn-icon {
  font-size: 1em;
}

@media only screen and (max-width: 768px){
  .paginator-table thead{
    display: none;
  }

  .paginator-table tr,
  .paginator-table td {
    display: block;
    width: 100%;
  }

  .paginator-table tr {
    margin-bottom: 1rem;
  }

  .paginator-table tbody tr td{
    text-align: right;
    position: relative;
  }

  .paginator-table td::before{
    content: attr(data-table);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
    padding-left: 1.2rem;
  }
}
/* Custom Table */

/* custom-pagination */
.paginator {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: end;
}

.paginator-btn {
  border: 1px solid #ccc;
  background-color: white;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.paginator-btn:hover {
  background-color: #f0f0f0;
}

.paginator-btn-active {
  background-color: #e0e0e0;
  font-weight: bold;
}

.paginator-btn-disabled {
  color: #d0d0d0;
  cursor: default;
}
/* custom-pagination */

/* Leaflet map */
.map-container {
  height: auto;
}

#map{
  height: 100%;
  width: 100%;
}